import httpRequest from "Config/httpsClient";
import { pseudoRandomBytes } from "crypto-browserify";

// get appointment
export const getAppointments = async (token) => {
  try {
    const header = {
      token: token,
    };

    const promise = await httpRequest.get("appointments", {
      headers: header,
    });
    return await promise.data;
  } catch (e) {}
};

// get general info
export const getGeneralInfo = async (token) => {
  try {
    const header = {
      token: token,
    };
    const promise = await httpRequest.get("admin-profile", {
      headers: header,
    });
    return await promise.data;
  } catch (e) {}
};

// get notification based on type
export const getNotificationTabs = async (token) => {
  try {
    const header = { token };
    const promise = await httpRequest.get("get-notification-tabs", {
      headers: header,
    });
    return await promise.data;
  } catch (e) {}
};

// get notification
export const getNotification = async (token) => {
  try {
    const header = {
      token: token,
    };
    const promise = await httpRequest.get("get-recent-notification", {
      headers: header,
    });
    return await promise.data;
  } catch (e) {}
};

// get full notification
export const getFullNotification = async (token, type) => {
  try {
    const header = {
      token: token,
      type,
    };
    const promise = await httpRequest.get("get-notification", {
      headers: header,
    });
    return await promise.data;
  } catch (e) {}
};

// get notification tabs total count
export const getNotificationTabsTotalCount = async (token) => {
  try {
    const header = { token };
    const promise = await httpRequest.get("get-notification-tabs-total-count", {
      headers: header,
    });
    return await promise.data;
  } catch (e) {}
};

// get teams data
export const renderTeam = async (token, category, orgId, location) => {
  try {
    const header = {
      token,
      category,
      orgId,
      location,
    };

    const promise = await httpRequest.get("employee-list", {
      headers: header,
    });
    return await promise.data;
  } catch (e) {}
};

// get search result
export const search = async (keyword, token) => {
  try {
    const header = {
      token: token,
      searchKeywords: keyword,
    };
    const promise = await httpRequest.get("search-page", { headers: header });
    return await promise.data;
  } catch (e) {}
};

// get folder data
export const folderData = async (token) => {
  try {
    const header = {
      token: token,
    };
    const promise = await httpRequest.get("get-folders", { headers: header });
    return await promise.data;
  } catch (e) {}
};

// get subfolder data
export const getStructure = async (token, pID) => {
  try {
    const header = {
      token: token,
      parentUniqueId: pID,
    };
    const promise = await httpRequest.get("get-structure", { headers: header });
    return await promise.data;
  } catch (e) {}
};

// get organization data
export const getOrganizationData = async (token, connId) => {
  try {
    const header = {
      token,
      connId,
    };
    const promise = await httpRequest.get("connection-profile", {
      headers: header,
    });
    return await promise.data;
  } catch (e) {}
};

/*
 * Get Organization list with team permission
 */
export const getOrgListWithPermission = async (token, connId, uniqueId) => {
  try {
    const header = {
      token,
      connId,
      uniqueId,
    };

    const promise = await httpRequest.get("org-list-with-permission", {
      headers: header,
    });
    return await promise.data;
  } catch (e) {}
};

/*
 * Update Organization team permission update
 */
export const updateOrganizationTeamPermission = async (
  token,
  empId,
  connId,
  permission,
  key
) => {
  try {
    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    const promise = await httpRequest.post(
      "change-Permission",
      {
        token,
        emp_id: empId,
        conn_id: connId,
        permission,
        key,
      },
      { headers: header }
    );
    return await promise.data;
  } catch (e) {}
};

// get trash data
export const getTrashData = async (token) => {
  try {
    const header = {
      token,
    };
    const promise = await httpRequest.get("get-trash", {
      headers: header,
    });
    return await promise.data;
  } catch (e) {}
};

// get organization data
export const getOrganizationConnection = async (token, connId) => {
  try {
    const header = {
      token,
      connId,
    };
    const promise = await httpRequest.get("organization-connection", {
      headers: header,
    });
    return await promise.data;
  } catch (e) {}
};

// handle sharedassetrequest
export const sharedAssetRequest = async (token, assetId, connId) => {
  try {
    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    const promise = await httpRequest.post(
      "shared-request-asset",
      {
        assetId: assetId,
        conn_id: connId,
      },
      { headers: header }
    );
    return await promise.data;
  } catch (e) {}
};
export const cloneAssetRequest = async (
  token,
  connId,
  assetId,
  notificationId
) => {
  try {
    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    const promise = await httpRequest.post(
      "clone-asset",
      {
        assetId: assetId,
        conn_id: connId,
        notificationId: notificationId,
      },
      { headers: header }
    );
    return await promise.data;
  } catch (e) {}
};
export const RejectAssetRequest = async (
  token,
  connId,
  assetId,
  notificationId
) => {
  try {
    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    const promise = await httpRequest.post(
      "reject-shared-asset",
      {
        assetId: assetId,
        conn_id: connId,
        notificationId: notificationId,
      },
      { headers: header }
    );
    return await promise.data;
  } catch (e) {}
};
// share internal folder
export const getInternalSharedFolder = async (
  token,
  connId,
  uniqueId,
  destinationUniqueId
) => {
  try {
    const header = {
      token,
      connId,
      uniqueId,
      destinationUniqueId,
    };
    const promise = await httpRequest.get("get-internal-shared-folder", {
      headers: header,
    });
    return await promise.data;
  } catch (e) {}
};

// get scan shared folder
export const scanSharedFolder = async (token, connId, uniqueId) => {
  try {
    const header = {
      token,
      connId,
      uniqueId,
    };
    const promise = await httpRequest.get("scan-shared-folder", {
      headers: header,
    });
    return await promise.data;
  } catch (e) {}
};

// get recent user list
export const getUserList = async (token, users) => {
  try {
    const header = {
      "Content-Type": "application/json",
      token,
    };
    const promise = await httpRequest.post(
      "users-detail",
      { users: users },
      {
        headers: header,
      }
    );
    return await promise.data;
  } catch (e) {}
};

// get all users list
export const getTeamListForGroup = async (token, empId) => {
  try {
    const header = {
      token,
      empId,
    };
    const promise = await httpRequest.get("connection-with-team", {
      headers: header,
    });
    return await promise.data;
  } catch (e) {}
};

// get new group data
export const newDataGroup = async (token, groupUniqueId) => {
  try {
    const header = {
      token: token,
      groupUniqueId,
    };
    const promise = await httpRequest.get("group-members", { headers: header });
    return await promise.data;
  } catch (e) {}
};

// status of the room
export const statusOfRoom = async (token, mstoken, id) => {
  try {
    const header = {
      "Content-Type": "application/json",
      token,
      mstoken,
      id,
    };
    const promise = await httpRequest.get("status-room-ById", {
      headers: header,
    });
    return await promise.data;
  } catch (e) {}
};

// list of organization in appointment
export const listOfAppointOrg = async (token, searchKeywords) => {
  try {
    const header = {
      "Content-Type": "application/json",
      token,
      searchKeywords,
    };
    const promise = await httpRequest.get("connections-with-filter", {
      headers: header,
    });
    return await promise.data;
  } catch (e) {}
};

// list of users in appointment
export const listOfAppointUsers = async (token, connId, searchKeywords) => {
  try {
    const header = {
      "Content-Type": "application/json",
      token,
      searchKeywords,
    };
    const promise = await httpRequest.post(
      "connection-with-fullTeam",
      { connId },
      {
        headers: header,
      }
    );
    return await promise.data;
  } catch (e) {}
};

// list of users in appointment with permission
export const listOfServiceTicketPermissionUsers = async (
  token,
  scope,
  searchKeywords
) => {
  try {
    const header = {
      "Content-Type": "application/json",
      token,
      scope,
      searchKeywords,
    };
    const promise = await httpRequest.get(
      "serviceTicket-permissionUser-withAdmin",
      {
        headers: header,
      }
    );
    return await promise.data;
  } catch (e) {}
};

// list of users in appointment
export const listOfServiceTicketUsers = async (
  token,
  connId,
  searchKeywords,
   scope
) => {
  try {
    const header = {
      "Content-Type": "application/json",
      token,
      connId,
      searchKeywords,
      scope
    };
    const promise = await httpRequest.get("service-connection-team", {
      headers: header,
    });
    return await promise.data;
  } catch (e) {}
};

// list of users in appointment
export const getFullAppointments = async (token, type) => {
  try {
    const header = {
      "Content-Type": "application/json",
      token,
      type,
    };
    const promise = await httpRequest.get("get-appointments", {
      headers: header,
    });
    return await promise.data;
  } catch (e) {}
};

// list of users in appointment
export const getSpecificFolder = async (token, connId, searchKeywords) => {
  try {
    const header = {
      "Content-Type": "application/json",
      token,
      searchKeywords,
    };
    const promise = await httpRequest.post(
      "folders-list",
      { connId },
      {
        headers: header,
      }
    );
    return await promise.data;
  } catch (e) {}
};

// get zip link
export const getZip = async (
  token,
  uniqueId,
  folderScope,
  type,
  expiryDays
) => {
  try {
    const header = {
      "Content-Type": "application/json",
      token,
      uniqueId,
      folderScope,
      type,
      expiryDays,
    };
    const promise = await httpRequest.get("make-zip", {
      headers: header,
    });
    return await promise.data;
  } catch (e) {}
};

// get superadmin orgnization list
export const renderOrganizations = async (token, searchKeywords) => {
  try {
    const header = {
      token: token,
      searchKeywords,
    };

    const promise = await httpRequest.get("admin/organization-list", {
      headers: header,
    });
    return await promise.data;
  } catch (e) {}
};

// get superadmin employee list
export const renderEmployees = async (token, orgId) => {
  try {
    const header = {
      token,
      orgId,
    };

    const promise = await httpRequest.get("admin/employee-list", {
      headers: header,
    });
    return await promise.data;
  } catch (e) {}
};

// get category data
export const getCategoryData = async (token, orgId, location_id) => {
  try {
    const header = {
      token,
      orgId,
      location_id,
    };
    const promise = await httpRequest.get("category", {
      headers: header,
    });
    return await promise.data;
  } catch (e) {}
};

// get location data
export const getLocationData = async (token, orgId) => {
  try {
    const header = {
      token,
      orgId,
    };
    const promise = await httpRequest.get("location", {
      headers: header,
    });
    return await promise.data;
  } catch (e) {}
};

// get internal folder data
export const getInternalFolder = async (token) => {
  try {
    const header = {
      token,
    };
    const promise = await httpRequest.get("get-internal-folder", {
      headers: header,
    });
    return await promise.data;
  } catch (e) {}
};

// get file explorer search
export const fileExplorerSearch = async (token, searchkeywords, connId) => {
  try {
    const header = {
      token,
      searchkeywords,
      connId,
    };
    const promise = await httpRequest.get("search-folder-list", {
      headers: header,
    });
    return await promise.data;
  } catch (e) {}
};
export const getAssetAllFolder = async (token, assetId) => {
  try {
    const header = {
      token,
    };
    const body = {
      assetId: assetId,
    };
    const promise = await httpRequest.post("get-asset-Allfolder", body, {
      headers: header,
    });
    return await promise.data;
  } catch (e) {}
};

// get ticket id
export const getTicketId = async (token) => {
  try {
    const header = {
      token,
    };
    const promise = await httpRequest.get("ticket-id", {
      headers: header,
    });
    return await promise.data;
  } catch (e) {}
};

// get ticket list
export const getTicketList = async (
  token,
  filter,
  searchKeywords,
  location,
  categoryId
) => {
  try {
    const header = {
      token,
      filter,
      searchKeywords,
      location,
      categoryId,
    };
    const promise = await httpRequest.get("org-ticket", {
      headers: header,
    });
    return await promise.data;
  } catch (e) {}
};

// get internal ticket list
export const getInternalTicketList = async (
  token,
  filter,
  searchKeywords,
  location,
  categoryId
) => {
  try {
    const header = {
      token,
      filter,
      searchKeywords,
      location,
      categoryId,
    };
    const promise = await httpRequest.get("internal-tickets-list", {
      headers: header,
    });
    return await promise.data;
  } catch (e) {}
};

// get personalized ticket list
export const getPersonalizedTicketList = async (
  token,
  filter,
  searchKeywords,
  type
) => {
  try {
    const header = {
      token,
      filter,
      searchKeywords,
      type,
    };
    const promise = await httpRequest.get("assign-ticket", {
      headers: header,
    });
    return await promise.data;
  } catch (e) {}
};

// get ticket list
export const getRequestorList = async (token, filter, searchKeywords) => {
  try {
    const header = {
      token,
      filter,
      searchKeywords,
    };
    const promise = await httpRequest.get("requestor-ticket", {
      headers: header,
    });
    return await promise.data;
  } catch (e) {}
};

// get non-connect organizatons list
export const getNonConnectedOrgs = async (token, searchKeywords) => {
  try {
    const header = {
      token,
      searchKeywords,
    };
    const promise = await httpRequest.get("not-connected-org", {
      headers: header,
    });
    return await promise.data;
  } catch (e) {}
};

// get service ticket details
export const serviceTicketDetails = async (token, ticketId) => {
  try {
    const header = {
      token,
      ticketId,
    };
    const promise = await httpRequest.get("ticket-by-id", {
      headers: header,
    });
    return await promise.data;
  } catch (e) {}
};

// get internal team members
export const getInternalMembers = async (token) => {
  try {
    const header = {
      token,
    };
    const data = await httpRequest.get("emp_from_same_orgination", {
      headers: header,
    });
    return await data;
  } catch (e) {}
};

// get external team members
export const getExternalMembers = async (token, external_orgi_id) => {
  try {
    const header = {
      token,
      external_orgi_id,
    };
    const data = await httpRequest.get("emp_from_diffrent_orgination", {
      headers: header,
    });
    return await data;
  } catch (e) {}
};

// request video chat
export const requestVideoChatRoom = async (token, connId) => {
  try {
    const header = {
      token,
      connId,
    };

    const data = await httpRequest.get("request-video-chat", {
      headers: header,
    });
    return await data;
  } catch (e) {}
};

// get user management page users list for tickets access
export const getUserListServiceTicketManagement = async (
  token,
  haveAccess,
  searchKeywords,
  scope,
  location,
  team
) => {
  try {
    const header = {
      token,
      haveAccess,
      searchKeywords,
      scope,
      location,
      team,
    };

    const data = await httpRequest.get("user-service-ticket-Permissions", {
      headers: header,
    });
    return await data;
  } catch (e) {}
};

// get ticket manager list
export const getManagerList = async (
  token,
  searchKeywords,
  external_org_id,
  assign_id,
  type
) => {
  try {
    const header = {
      token,
      searchKeywords,
      external_org_id,
      assign_id,
      type,
    };

    const data = await httpRequest.get("get-ticket-manger", {
      headers: header,
    });
    return await data;
  } catch (e) {}
};

// tag users
export const tagUsers = async (token, tag_user, unique_id) => {
  try {
    const header = {
      token,
    };

    const data = await httpRequest.post(
      "tag-notification",
      { tag_user, unique_id },
      {
        headers: header,
      }
    );
    return await data;
  } catch (e) {}
};

// get user management page users list for user management access
export const getUserListUserManagement = async (
  token,
  haveAccess,
  searchKeywords
) => {
  try {
    const header = {
      token,
      haveAccess,
      searchKeywords,
    };

    const data = await httpRequest.get("user-manager-permission", {
      headers: header,
    });
    return await data;
  } catch (e) {}
};

// get user management page users list for file management access
export const getUserListFileManagement = async (token) => {
  try {
    const header = {
      token,
    };

    const data = await httpRequest.get("user-fileManager-permissions", {
      headers: header,
    });
    return await data;
  } catch (e) {}
};

// get user management page users list for file management access
export const getUserListPortfolioAndWorkbookManagement = async (token) => {
  try {
    const header = {
      token,
    };

    const data = await httpRequest.get("user-portfolio-permissions", {
      headers: header,
    });
    return await data;
  } catch (e) {}
};
export const getAssetPermissions = async (token) => {
  try {
    const header = {
      token,
    };

    const data = await httpRequest.get("user-asset-permissions", {
      headers: header,
    });
    return await data;
  } catch (e) {}
};
// check user management page all accesses
export const getUserPermissions = async (token) => {
  try {
    const header = {
      token,
    };

    const data = await httpRequest.get("check-user-permissions", {
      headers: header,
    });
    return await data;
  } catch (e) {}
};

// get user management page users list for credit management access
export const getUserListCreditManagement = async (
  token,
  haveAccess,
  searchKeywords
) => {
  try {
    const header = {
      token,
      haveAccess,
      searchKeywords,
    };
    const data = await httpRequest.get("user-credit-money-permissions", {
      headers: header,
    });
    return await data;
  } catch (e) {}
};

// getting the data of raised request
export const getRaiseRequestData = async (token, serviceRequestId) => {
  try {
    const header = {
      token,
      serviceRequestId,
    };

    const data = await httpRequest.get("service-request", {
      headers: header,
    });
    return await data;
  } catch (e) {}
};

// get client secret key
export const getClientSecretKey = async (
  token,
  stripeCustomerId,
  amount,
  description,
  creditType,
  connId,
  paymentMethodId
) => {
  try {
    const header = {
      token,
    };

    const data = await httpRequest.post(
      "add-payment-intent",
      {
        stripeCustomerId,
        amount,
        description,
        creditType,
        connId,
        paymentMethodId,
      },
      {
        headers: header,
      }
    );
    return await data;
  } catch (e) {}
};

// get credit balance
export const getClientCreditBalance = async (token) => {
  try {
    const header = {
      token,
    };

    const data = await httpRequest.get("credit-balance", {
      headers: header,
    });
    return await data;
  } catch (e) {}
};

// get credit balance
export const getSuperAdminClientCreditBalance = async (token) => {
  try {
    const header = {
      token,
    };

    const data = await httpRequest.get("get-total-credit", {
      headers: header,
    });
    return await data;
  } catch (e) {}
};

// get transcation list
export const getTranscationListApi = async (token, searchKeywords, filter) => {
  try {
    const header = {
      token,
      searchKeywords,
      filter,
    };

    const data = await httpRequest.get("transaction-activity", {
      headers: header,
    });
    return await data;
  } catch (e) {}
};

// get all refund requests
export const getRefundRequests = async (token, searchKeywords, filter) => {
  try {
    const header = {
      token,
      searchKeywords,
      filter,
    };
    const data = await httpRequest.get("refund", {
      headers: header,
    });
    return await data;
  } catch (e) {}
};

// get respective rate
export const getRespectiveRates = async (token) => {
  try {
    const header = {
      token,
    };
    const data = await httpRequest.get("rates", {
      headers: header,
    });
    return await data;
  } catch (e) {}
};

// get details by refund Id
export const getRefundIdDetails = async (token, refundId) => {
  try {
    const header = {
      token,
      refundId,
    };
    const data = await httpRequest.get("refund-by-id", {
      headers: header,
    });
    return await data;
  } catch (e) {}
};

// get all subscriptions
export const getAllSubscriptions = async (token) => {
  try {
    const header = {
      token,
    };
    const data = await httpRequest.get("subscription-list", {
      headers: header,
    });
    return await data;
  } catch (e) {}
};
export const getAssets = async (token, searchKeyword) => {
  try {
    const header = {
      token,
      searchKeywords: searchKeyword,
    };
    const data = await httpRequest.get("get-asset", {
      headers: header,
    });
    return await data;
  } catch (e) {}
};
export const deleteAssets = async (token, assetId) => {
  try {
    const header = {
      token,
    };
    const body = {
      unique_id: assetId,
    };
    const data = await httpRequest.post("archive-asset", body, {
      headers: header,
    });
    return await data;
  } catch (e) {}
};
export const getAssetByUniqueID = async (token, uniqueid) => {
  try {
    const header = {
      token,
    };

    const body = {
      unique_id: uniqueid,
    };

    const data = await httpRequest.post("get-asset-byUniqueId", body, {
      headers: header,
    });

    return await data;
  } catch (e) {
    console.error(e); // Log the error for debugging
  }
};
export const getuploadedfiles = async (token, uniqueid) => {
  try {
    const header = {
      token,
    };

    const body = {
      assetId: uniqueid,
    };

    const data = await httpRequest.post("browser-asset-media", body, {
      headers: header,
    });

    return await data;
  } catch (e) {
    console.error(e); // Log the error for debugging
  }
};
export const getpartsuploadedfiles = async (token, uniqueid, partId) => {
  try {
    const header = {
      token,
      parentUniqueId: uniqueid,
      partId: partId,
    };

    const data = await httpRequest.get("get-part-files", {
      headers: header,
    });

    return await data;
  } catch (e) {
    console.error(e); // Log the error for debugging
  }
};
export const getAssetFolders = async (token, assetId, parent_unique_id) => {
  try {
    const header = {
      token,
    };

    // Ensure parent_unique_id is explicitly sent, even if it's 0
    const payload = {
      assetId,
      parent_unique_id: parent_unique_id === 0 ? 0 : parent_unique_id,
    };

    const response = await httpRequest.post("get-asset-folder", payload, {
      headers: header,
    });

    return response; // Removed unnecessary await
  } catch (e) {
    console.error("Error fetching asset folders:", e);
    throw e; // Propagate error to allow further handling
  }
};

export const getPartFolders = async (token, partId, parentUniqueId) => {
  try {
    const data = await httpRequest.get(
      "get-asset-part-folder",

      {
        headers: { token, partId, parentUniqueId },
      }
    );
    return await data;
  } catch (e) {
    console.error(e);
  }
};
export const getAssetServiceTicket = async (
  token,
  uniqueid,
  searchKeywords,
  filter,
  assetTicketScope
) => {
  try {
    const header = {
      token,
      assetId: uniqueid,
      searchKeywords: searchKeywords,
      filter: filter,
      assetTicketScope
    };

    const data = await httpRequest.get("get-asset-service-ticket", {
      headers: header,
    });

    return await data;
  } catch (e) {
    console.error(e);
  }
};
export const getAssetPart = async (token, uniqueid) => {
  try {
    const header = {
      token,
      assetId: uniqueid,
    };

    const data = await httpRequest.get("get-asset-part", {
      headers: header,
    });

    return await data;
  } catch (e) {
    console.error(e);
  }
};
export const getAssetAllPart = async (token) => {
  try {
    const header = {
      token,
    };

    const data = await httpRequest.get("get-all-part", {
      headers: header,
    });

    return await data;
  } catch (e) {
    console.error(e);
  }
};
export const getAssetPartbyId = async (token, uniqueid) => {
  try {
    const header = {
      token,
      uniqueId: uniqueid,
    };

    const data = await httpRequest.get("get-asset-part-by-uniqueId", {
      headers: header,
    });

    return await data;
  } catch (e) {
    console.error(e);
  }
};
export const getSharedAssets = async (token, searchKeyword) => {
  try {
    const header = {
      token,
      searchKeywords: searchKeyword,
    };
    const data = await httpRequest.get("get-shared-asset", {
      headers: header,
    });
    return await data;
  } catch (e) {}
};

// get all subscriptions rates
export const getAllSubscriptionsRates = async (token, rateType) => {
  try {
    const header = {
      token,
      rateType,
    };
    const data = await httpRequest.get("get-all-rate", {
      headers: header,
    });
    return await data;
  } catch (e) {}
};

// get all subscribers
export const getAllSubscribers = async (token) => {
  try {
    const header = {
      token,
    };
    const data = await httpRequest.get("my-subscriber", {
      headers: header,
    });
    return await data;
  } catch (e) {}
};

// get Bank Details
export const getBankDetails = async (token) => {
  try {
    const header = {
      token,
    };
    const data = await httpRequest.get("bank", {
      headers: header,
    });
    return await data;
  } catch (e) {}
};

// get Credit Card Details
export const getCreditCardDetails = async (token) => {
  try {
    const header = {
      token,
    };
    const data = await httpRequest.get("card", {
      headers: header,
    });
    return await data;
  } catch (e) {}
};

// get All Transfer Out Requests
export const getAllTransferOutRequests = async (
  token,
  searchKeywords,
  filter
) => {
  try {
    const header = {
      token,
      searchKeywords,
      filter,
    };
    const data = await httpRequest.get("transfer-outs", {
      headers: header,
    });
    return await data;
  } catch (e) {}
};

// get all transaction activity
export const getTranscationActivityApi = async (token, orgId) => {
  try {
    const header = {
      token,
      orgId,
    };
    const data = await httpRequest.get("transaction-activity-orgId", {
      headers: header,
    });
    return await data;
  } catch (e) {}
};

// get all Amnius Admin
export const getAllAmniusAdmin = async (token) => {
  try {
    const header = {
      token,
    };
    const data = await httpRequest.get("get-Amnius-Admin", {
      headers: header,
    });
    return await data;
  } catch (e) {}
};

// get portfolio data
export const getPortfolioData = async (
  token,
  classify,
  parent_unique_id,
  orgId
) => {
  try {
    const header = {
      token,
      classify,
      parent_unique_id,
      orgId,
    };
    const data = await httpRequest.get("get-portfolio", {
      headers: header,
    });
    return await data;
  } catch (e) {}
};

// get user data
export const getUserDetails = async (token) => {
  try {
    const header = {
      token,
    };
    const data = await httpRequest.get("user-data", {
      headers: header,
    });
    return await data;
  } catch (e) {}
};

// get workbook files
export const getWorkBookFiles = async (token, parentUniqueId) => {
  try {
    const header = {
      token,
      parentUniqueId,
    };
    const data = await httpRequest.get("get-workbook-files", {
      headers: header,
    });
    return await data;
  } catch (e) {}
};

// get searched portfolio folder
export const getSearchedPortfolioFolder = async (token, searchKeywords) => {
  try {
    const header = {
      token,
      searchKeywords,
    };
    const data = await httpRequest.get("search-portfolio", {
      headers: header,
    });
    return await data;
  } catch (e) {}
};

// get assigned workbook employee list
export const getAssignedWorkbookData = async (
  token,
  parentUniqueId,
  assignTo
) => {
  try {
    const header = {
      token,
      parentUniqueId,
      assignTo,
    };
    const data = await httpRequest.get("get-assign-workbook", {
      headers: header,
    });
    return await data;
  } catch (e) {}
};

// get trash api
export const getTrashDataByType = async (token, type) => {
  try {
    const header = { token, type };
    const data = await httpRequest.get("get-trash", { headers: header });
    return await data;
  } catch (e) {}
};

// get new mstoken api
export const getNewMsToken = async (token) => {
  try {
    const header = { token };
    const data = await httpRequest.get("msToken", { headers: header });
    return await data;
  } catch (e) {}
};

// get chat image
export const getChatImagesByPath = async (token, path) => {
  try {
    const header = { token };
    const data = await httpRequest.post(
      "get-image-fullPath",
      { path },
      { headers: header }
    );
    return await data;
  } catch (e) {}
};

// get internal service tickets
export const getAllInternalTickets = async (
  token,
  filter,
  searchKeywords,
  location,
  categoryId
) => {
  try {
    const header = { token, filter, searchKeywords, location, categoryId };
    const data = await httpRequest.get("internal-tickets-list", {
      headers: header,
    });
    return await data;
  } catch (e) {}
};

// get room details
export const getVideoRoomDetails = async (token, roomId) => {
  try {
    const header = { token };
    const data = await httpRequest.post(
      "get-room-detail",
      { roomId },
      { headers: header }
    );
    return await data;
  } catch (e) {}
};

// get storage data
export const getStorageData = async (token, orgId) => {
  try {
    const header = {
      token,
      orgId,
    };
    const data = await httpRequest.get("get-storage-detail", {
      headers: header,
    });
    return await data;
  } catch (e) {}
};

// get storage data for super admin
export const getSuperAdminStorageData = async (token) => {
  try {
    const header = {
      token,
    };
    const data = await httpRequest.get("admin/get-all-storage", {
      headers: header,
    });
    return await data;
  } catch (e) {}
};

// get all organization Storage Data
export const getAllOrgStorageData = async (token) => {
  try {
    const header = {
      token,
    };
    const data = await httpRequest.get("admin/all-org-with-storage", {
      headers: header,
    });
    return await data;
  } catch (e) {}
};

// get storage plans
export const getStoragePlans = async (token) => {
  try {
    const header = {
      token,
    };
    const data = await httpRequest.get("get-all-storage-plan", {
      headers: header,
    });
    return await data;
  } catch (e) {}
};

// get subscription management details
export const getAllSubscriptionManagement = async (
  token,
  haveAccess,
  searchKeywords
) => {
  try {
    const header = {
      token,
      haveAccess,
      searchKeywords,
    };

    const data = await httpRequest.get("user-susbcription-permissions", {
      headers: header,
    });
    return await data;
  } catch (e) {}
};

// get admin rights management details
export const getAllAdminRightsManagement = async (
  token,
  haveAccess,
  searchKeywords
) => {
  try {
    const header = {
      token,
      haveAccess,
      searchKeywords,
    };

    const data = await httpRequest.get("user-admin-permissions", {
      headers: header,
    });
    return await data;
  } catch (e) {}
};

// get category names
export const getCategoryListNames = async (token) => {
  try {
    const header = {
      token,
    };

    const data = await httpRequest.get("category-without-location", {
      headers: header,
    });
    return await data;
  } catch (e) {}
};

// change teams
export const changeTeamsApi = async (token, category_id, ticket_id) => {
  try {
    const header = { token };
    const data = await httpRequest.post(
      "change-ticket-category",
      { category_id, ticket_id },
      { headers: header }
    );
    return await data;
  } catch (e) {}
};

// get tech listing
export const getTechListing = async (token, categoryId) => {
  try {
    const header = {
      token,
      categoryId,
    };

    const data = await httpRequest.get("user-by-categoryId", {
      headers: header,
    });
    return await data;
  } catch (e) {}
};

// remove chat files
export const deleteFilesServer = async (token, path) => {
  try {
    const header = { token };
    const data = await httpRequest.post(
      "remove-chat-files",
      { path },
      { headers: header }
    );
    return await data;
  } catch (e) {}
};

// get assign tech internal tickets
export const getAssignTech = async (token, locationId, categoryId) => {
  try {
    const header = {
      token,
      locationId,
      categoryId,
    };
    const promise = await httpRequest.get("user-location-category", {
      headers: header,
    });
    return await promise.data;
  } catch (e) {}
};

//create asset folder
export const createAssetFolder = async (
  token,
  assetId,
  name,

  parent_unique_id,
  type,
  path
) => {
  try {
    const promise = await httpRequest.post(
      "create-asset-folder",
      { assetId, name, parent_unique_id, path,type},
      {
        headers: { token },
      }
    );
    return await promise.data;
  } catch (e) {}
};
export const createPartFolder = async (
  token,
  assetId,
  partId,
  type,
  path,
  parent_unique_id,
  partHash,
  name
) => {
  try {
    const promise = await httpRequest.post(
      "create-asset-part",
      { partId, parent_unique_id, type, assetId, partHash, name ,path},
      {
        headers: { token },
      }
    );
    return await promise.data;
  } catch (e) {}
};
//archive asset folder
export const archiveAssetFolder = async (token, unique_id) => {
  try {
    const promise = await httpRequest.post(
      "archive-asset-folder",
      { unique_id },
      {
        headers: { token },
      }
    );
    return await promise.data;
  } catch (e) {}
};
export const archivePartFolder = async (token, unique_id) => {
  try {
    const promise = await httpRequest.post(
      "archive-asset-part-folder",
      { unique_id },
      {
        headers: { token },
      }
    );
    return await promise.data;
  } catch (e) {}
};
//archive asset folder
export const renameAssetFolder = async (token, unique_id, new_name,type,path)=>{
  try {
    const promise = await httpRequest.post(
      "rename-asset-folder",
      { unique_id, new_name ,type,path},
      {
        headers: { token },
      }
    );
    return await promise.data;
  } catch (e) {}
};
export const renamePartFolder = async (token, unique_id, new_name,type,path) => {
  try {
    const promise = await httpRequest.post(
      "rename-asset-part-folder",
      { unique_id, new_name ,type,path},
      {
        headers: { token },
      }
    );
    return await promise.data;
  } catch (e) {}
};
