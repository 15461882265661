import "./STPremiumPage.css";

import React, { useEffect, useState } from "react";
import {
  getAllSubscriptions,
  getPersonalizedTicketList,
  getRequestorList,
  getTicketList,
} from "Config/API/get";
import {
  notConnectedAmniusAction,
  raiseRequestDataAction,
  selectedOrganizationSTAction,
} from "Redux/action/variable.action";
import { useDispatch, useSelector } from "react-redux";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Headers from "Components/Header/Headers";
import Low from "Media/icons/Low.png";
import Moderate from "Media/icons/Moderate.png";
import Navigation from "Components/SideNavBar/Navigation";
import Pause from "Media/icons/Pause.png";
import RenderConfirm from "Components/Popup/Option/Container/RenderConfirm";
import SearchIcon from "@mui/icons-material/Search";
import Urgent from "Media/icons/Urgent.png";
import { ticketIdAction } from "Redux/action/variable.action";
import { toast } from "react-toastify";
import useFullPageLoader from "Hooks/useFullPageLoader";
import { useNavigate } from "react-router-dom";
import RenderRequestTypePopup from "Components/Popup/RequestTypePopup/Container/RenderRequestTypePopup";

const STPremiumPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let doneTypingInterval = 1500;
  let typingTimer;
  const loggedInUserDetails = useSelector(
    (state) => state.authenticationReducer
  );
  const pageType = useSelector((state) => state.servicePageTypeReducer);
  const [loader, setLoader, resetLoader] = useFullPageLoader();

  const [ticketList, setTicketList] = useState("");
  const [personalizedTicketList, setPersonalizedTicketList] = useState("");
  const token = localStorage.getItem("token");

  const [searchKeyword, setSearchKeyword] = useState("");
  const [filterKeyword, setFilterKeyword] = useState("");
  const [personalizedFilterKeyword, setPersonalizedFilterKeyword] =
    useState("");
  const [requestTypeconfirmPopup, setRequestTypeConfirmPopup] = useState(false);
  const [confirmPopup, setConfirmPopup] = useState(false);

  useEffect(() => {
    if (pageType === undefined) {
      navigate("/service-ticket-dashboard");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // get all subscriptions
  useEffect(() => {
    const response = getAllSubscriptions(token);
    response.then((res) => {
      if (res?.data?.status) {
        const filteredSubscription = res.data.data?.filter(
          (item) => item?.subscription_type === "service_ticket"
        );
        if (
          filteredSubscription[0]?.subscriptionStatus?.toString() === "false" &&
          pageType?.toString() !== "NON-PREMIUM"
        ) {
          navigate("/service-ticket-dashboard");
          toast("You don't have access of this Service", {
            type: "info",
          });
          return;
        }
      } else
        toast("Failed! Try again later", {
          type: "error",
        });
    });
  });

  // get ticket list
  useEffect(() => {
    if (pageType?.toString() === "PREMIUM") {
      setLoader();
      const ticket_status = getTicketList(token, "", "", "", "");
      ticket_status.then((res) => {
        resetLoader();
        if (res?.status) {
          const filteredTickets = res?.ticket?.filter(
            (item) => item?.severity_issue !== "closed"
          );
          setTicketList(filteredTickets);
        } else
          toast(res?.message, {
            type: "error",
          });
      });

      const personalized_ticket_status = getPersonalizedTicketList(
        token,
        "",
        "",
        "external"
      );
      personalized_ticket_status.then((res) => {
        if (res?.status) {
          const filteredTickets = res?.ticket?.filter(
            (item) => item?.severity_issue !== "closed"
          );
          setPersonalizedTicketList(filteredTickets);
        }
      });
    } else {
      setLoader();
      const ticket_status = getRequestorList(token, "", "");
      ticket_status.then((res) => {
        resetLoader();
        if (res?.status) {
          const filteredTickets = res?.ticket?.filter(
            (item) => item?.severity_issue !== "closed"
          );
          setTicketList(filteredTickets);
        } else
          toast(res?.message, {
            type: "error",
          });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filter_function = (value) => {
    if (pageType?.toString() === "PREMIUM") {
      setLoader();
      const ticket_status = getTicketList(token, value, searchKeyword, "", "");
      ticket_status.then((res) => {
        resetLoader();
        if (res?.status) {
          if (value === "all") {
            const filteredTickets = res?.ticket?.filter(
              (item) => item?.severity_issue !== "closed"
            );
            return setTicketList(filteredTickets);
          }
          setTicketList(res?.ticket);
        } else
          toast(res?.message, {
            type: "error",
          });
      });
    } else {
      setLoader();
      const ticket_status = getRequestorList(token, value, searchKeyword);
      ticket_status.then((res) => {
        resetLoader();
        if (res?.status) {
          if (value === "all") {
            const filteredTickets = res?.ticket?.filter(
              (item) => item?.severity_issue !== "closed"
            );
            return setTicketList(filteredTickets);
          }
          setTicketList(res?.ticket);
        } else
          toast(res?.message, {
            type: "error",
          });
      });
    }
  };

  const personalized_filter_function = (value) => {
    setLoader();
    const personalized_ticket_status = getPersonalizedTicketList(
      token,
      value,
      searchKeyword,
      "external"
    );
    personalized_ticket_status.then((res) => {
      resetLoader();
      if (res?.status) {
        if (value === "all") {
          const filteredTickets = res?.ticket?.filter(
            (item) => item?.severity_issue !== "closed"
          );
          return setPersonalizedTicketList(filteredTickets);
        }
        setPersonalizedTicketList(res?.ticket);
      } else
        toast(res?.message, {
          type: "error",
        });
    });
  };

  const search_fuction = (value) => {
    if (pageType?.toString() === "PREMIUM") {
      setLoader();
      const ticket_status = getTicketList(token, filterKeyword, value, "", "");
      ticket_status.then((res) => {
        resetLoader();
        if (res?.status) {
          setTicketList(res?.ticket);
        } else
          toast(res?.message, {
            type: "error",
          });
      });

      const personalized_ticket_status = getPersonalizedTicketList(
        token,
        personalizedFilterKeyword,
        value,
        "external"
      );
      personalized_ticket_status.then((res) => {
        if (res?.status) {
          setPersonalizedTicketList(res?.ticket);
        }
      });
    } else {
      setLoader();
      const ticket_status = getRequestorList(token, filterKeyword, value);
      ticket_status.then((res) => {
        resetLoader();
        if (res?.status) {
          setTicketList(res?.ticket);
        } else
          toast(res?.message, {
            type: "error",
          });
      });
    }
  };

  // creating tickets
  const requestTypeConnectedFunction = (option) => {
    if (option === "Internal")
      navigate("/create-internal-service-ticket", {
        state: "new ticket",
      });
    else setConfirmPopup(true);
    setRequestTypeConfirmPopup(false);
  };

  // connected or not function
  const connectedFunction = (option) => {
    if (option?.toString() === "Yes") {
      dispatch(notConnectedAmniusAction(false));
      navigate("/service-ticket-create", {
        state: "new ticket",
      });
      dispatch(selectedOrganizationSTAction(""));
      dispatch(raiseRequestDataAction(""));
    } else {
      dispatch(notConnectedAmniusAction(true));
      navigate("/service-ticket-create", {
        state: "new ticket",
      });
      dispatch(selectedOrganizationSTAction(""));
      dispatch(raiseRequestDataAction(""));
    }
  };
  return (
    <>
      <div className="stpmain-parent">
        <div className="stpmain-header">
          <Headers />
        </div>

        <div className="stpmain-navigation">
          <Navigation />
        </div>

        <div className="stpmain-main">
          <div className="stpmain-ticket-search">
            <input
              type="search"
              placeholder={
                pageType?.toString() === "PREMIUM"
                  ? "Search tickets by ticket # or requestor name"
                  : "Search tickets by ticket # or provider name"
              }
              onChange={(e) => {
                typingTimer = setTimeout(() => {
                  search_fuction(e.target.value);
                  setSearchKeyword(e.target.value);
                }, doneTypingInterval);
              }}
            />
            <SearchIcon />
          </div>
          {pageType?.toString() === "PREMIUM" &&
          (loggedInUserDetails?.ticket_external_read_role === "manager" ||
            loggedInUserDetails?.ticket_internal_read_role === "manager") ? (
            <div className="stpmain-options-btn">
              <button
                className={"stpmain-outline-btn-active"}
                onClick={() => {
                  navigate("/service-ticket-view-page");
                }}
              >
                EXTERNAL TICKETS
              </button>
              <button
                disabled={
                  loggedInUserDetails?.ticket_internal_role !== "manager" &&
                  loggedInUserDetails?.ticket_external_role !== "manager"
                }
                className={
                  loggedInUserDetails?.ticket_internal_role !== "manager" &&
                  loggedInUserDetails?.ticket_external_role !== "manager"
                    ? "external-rights"
                    : "create-ticket"
                }
                onClick={() => {
                  setRequestTypeConfirmPopup(true);
                }}
              >
                Create New Ticket
              </button>
              <button
                className={
                  loggedInUserDetails?.ticket_internal_read_role !== "manager"
                    ? "external-rights"
                    : "stpmain-outline-btn"
                }
                onClick={() => {
                  if (
                    loggedInUserDetails?.ticket_internal_read_role === "manager"
                  ) {
                    navigate("/all-internal-service-tickets");
                  }
                }}
              >
                INTERNAL TICKETS
              </button>
            </div>
          ) : null}
          <div className="stpmain-wrapper">
            <div className="stpmain-all-active">
              <div className="stpmain-top">
                <p className="sttop-heading">All Active Service Tickets</p>
                <div>
                  <p>Filter:</p>
                  <select
                    onChange={(e) => {
                      filter_function(e.target.value);
                      setFilterKeyword(e.target.value);
                    }}
                  >
                    <option value="all">All</option>
                    <option value="urgent">Urgent</option>
                    <option value="moderate">Moderate</option>
                    <option value="low">Low</option>
                    <option value="pause">Pause</option>
                    <option value="closed">Closed</option>
                  </select>
                </div>
              </div>
              <div className="stpmain-row-wrapper">
                <div className="stpmain-head-row" style={{ width: "25%" }}>
                  TICKET #
                </div>
                <div className="stpmain-head-row">
                  {pageType?.toString() === "PREMIUM"
                    ? "REQUESTOR"
                    : "PROVIDER"}
                </div>
                <div className="stpmain-head-row">ASSIGNED TECH</div>
                <div className="stpmain-head-row">SEVERITY</div>
                <div className="stpmain-head-row">DATE OPENED</div>
              </div>
              <div className="stpmain-bottom">
                {ticketList?.length?.toString() !== "0" ? (
                  ticketList?.map((curr, index) => {
                    return (
                      <div className="stpmain-content-wrapper" key={index}>
                        <div
                          className="stpmain-content-row"
                          style={{ width: "25%" }}
                          onClick={() => {
                            dispatch(ticketIdAction(curr?.ticket_id));
                            navigate("/service-ticket-details");
                          }}
                        >
                          {curr?.ticket_id}
                        </div>
                        <div className="stpmain-content-row">
                          {pageType?.toString() === "PREMIUM"
                            ? curr?.requestor
                            : curr?.provider}
                        </div>
                        <div className="stpmain-content-row">
                          {curr?.assign_tech}
                        </div>
                        <div className="stpmain-content-row">
                          {curr?.severity_issue?.toString() === "urgent" ? (
                            <>
                              <img src={Urgent} alt="Urgent" /> Urgent
                            </>
                          ) : curr?.severity_issue?.toString() ===
                            "moderate" ? (
                            <>
                              <img src={Moderate} alt="Moderate" /> Moderate
                            </>
                          ) : curr?.severity_issue?.toString() === "low" ? (
                            <>
                              <img src={Low} alt="Low" /> Low
                            </>
                          ) : curr?.severity_issue?.toString() === "pause" ? (
                            <>
                              <img src={Pause} alt="Pause" /> Pause
                            </>
                          ) : curr?.severity_issue?.toString() === "closed" ? (
                            <>
                              <span
                                style={{
                                  backgroundColor: "orange",
                                  borderRadius: "2px",
                                  textAlign: "center",
                                  width: "20px",
                                  height: "18px",
                                  color: "white",
                                  marginRight: "5px",
                                }}
                              >
                                C
                              </span>
                              Closed
                            </>
                          ) : null}
                        </div>
                        <div
                          className="stpmain-content-row stpmain-date-row"
                          style={{ position: "relative" }}
                        >
                          {curr?.create_at}

                          {curr?.scheduled_status?.toString() !== "no" ? (
                            curr?.scheduled_status === "green" ? (
                              <AccessTimeIcon style={{ color: "#5ca127" }} />
                            ) : (
                              <AccessTimeIcon style={{ color: "#d81c1c" }} />
                            )
                          ) : null}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="stpmain-msg">No Tickets Found</div>
                )}
              </div>
            </div>
            {pageType?.toString() === "PREMIUM" ? (
              <div className="stpmain-my-active">
                <div className="stpmain-top ">
                  <p className="sttop-heading">My Active Service Tickets</p>
                  <div>
                    <p>Filter:</p>
                    <select
                      onChange={(e) => {
                        personalized_filter_function(e.target.value);
                        setPersonalizedFilterKeyword(e.target.value);
                      }}
                    >
                      <option value="all">All</option>
                      <option value="urgent">Urgent</option>
                      <option value="moderate">Moderate</option>
                      <option value="low">Low</option>
                      <option value="pause">Pause</option>
                      <option value="closed">Closed</option>
                    </select>
                  </div>
                </div>
                <div className="stpmain-row-wrapper stpmain-right-side">
                  <div className="stpmain-head-row" style={{ width: "25%" }}>
                    TICKET #
                  </div>
                  <div className="stpmain-head-row">REQUESTOR</div>
                  <div className="stpmain-head-row">SEVERITY</div>
                  <div className="stpmain-head-row">DATE OPENED</div>
                </div>
                <div className="stpmain-bottom stpmain-right-side">
                  {personalizedTicketList?.length?.toString() !== "0" ? (
                    personalizedTicketList?.map((curr, index) => {
                      return (
                        <div className="stpmain-content-wrapper" key={index}>
                          <div
                            className="stpmain-content-row"
                            style={{ width: "25%" }}
                            onClick={() => {
                              dispatch(ticketIdAction(curr?.ticket_id));
                              navigate("/service-ticket-details");
                            }}
                          >
                            {curr?.ticket_id}
                          </div>
                          <div className="stpmain-content-row">
                            {curr?.requestor}
                          </div>
                          <div className="stpmain-content-row">
                            {curr?.severity_issue?.toString() === "urgent" ? (
                              <>
                                <img src={Urgent} alt="Urgent" /> Urgent
                              </>
                            ) : curr?.severity_issue?.toString() ===
                              "moderate" ? (
                              <>
                                <img src={Moderate} alt="Moderate" /> Moderate
                              </>
                            ) : curr?.severity_issue?.toString() === "low" ? (
                              <>
                                <img src={Low} alt="Low" /> Low
                              </>
                            ) : curr?.severity_issue?.toString() === "pause" ? (
                              <>
                                <img src={Pause} alt="Pause" /> Pause
                              </>
                            ) : curr?.severity_issue?.toString() ===
                              "closed" ? (
                              <>
                                <span
                                  style={{
                                    backgroundColor: "orange",
                                    borderRadius: "2px",
                                    textAlign: "center",
                                    width: "20px",
                                    height: "18px",
                                    color: "white",
                                    marginRight: "5px",
                                  }}
                                >
                                  C
                                </span>
                                Closed
                              </>
                            ) : null}
                          </div>
                          <div
                            className="stpmain-content-row stpmain-date-row"
                            style={{ position: "relative" }}
                          >
                            {curr?.create_at}

                            {curr?.scheduled_status?.toString() !== "no" ? (
                              curr?.scheduled_status?.toString() === "green" ? (
                                <AccessTimeIcon style={{ color: "#5ca127" }} />
                              ) : (
                                <AccessTimeIcon style={{ color: "#d81c1c" }} />
                              )
                            ) : null}
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="stpmain-msg">No Tickets Found</div>
                  )}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {requestTypeconfirmPopup ? (
        <RenderRequestTypePopup
          message={"Please Select Request Type"}
          option1={"External"}
          option2={"Internal"}
          funct={requestTypeConnectedFunction}
          hide={setRequestTypeConfirmPopup}
        />
      ) : null}
      {confirmPopup ? (
        <RenderConfirm
          message={"Is Requestor A Connected Amnius User?"}
          option1={"Yes"}
          option2={"No"}
          funct={connectedFunction}
          hide={setConfirmPopup}
        />
      ) : null}
      {loader}
    </>
  );
};

export default STPremiumPage;
